import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthService } from './core/modules/auth/services/auth.service';
import { environment } from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.less',
})
export class AppComponent implements OnInit {
	title = 'pam';
	token: any;

	constructor(
		private activatedRoute: ActivatedRoute,
		private authService: AuthService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params: any) => {
			console.log(
				params['token'],
				localStorage.getItem('user_id'),
				'AppComponentAppComponentAppComponent'
			);
			if ( params['token']) {
				this.authService.getDetails(params['token']).subscribe({
					next: (value) => {
						if (value) {
							localStorage.setItem(
								'user_id',
								value.user_attrib_uuid
							);
							localStorage.setItem(
								'user_Details',
								JSON.stringify(value)
							);

							switch (params['navigation']) {
								case 'persona':
									this.router.navigateByUrl('user-persona/list');
									break;
								case 'persona-list':
									this.router.navigateByUrl(
										'user-persona/list'
									);
									break;
								case 'learn':
									this.router.navigateByUrl(
										'conversations/learning'
									);
									break;
								case 'prep':
									this.router.navigateByUrl(
										'conversations/preparation'
									);
									break;

								case 'interview':
									this.router.navigateByUrl(
										'conversations/learning'
									);
									break;

								default:
									this.router.navigateByUrl('user-persona');
									break;
							}
						}
					},
					error: (err) => {
						window.location.href = `${environment.nextLeapURL}auth/login`;
					},
				});
			}
			// else {
			// 	if(localStorage.getItem('user_id')){
			// 		this.router.navigateByUrl('user-persona')
			// 	}else{
			// 	window.location.href = `${environment.nextLeapURL}auth/login`
			// 	}
			// }
		});
	}
}
