import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';;
import { provideHttpClient } from '@angular/common/http';
import { LearningPreparationFormComponent } from './core/modules/user-persona/components/learning-preparation-form/learning-preparation-form.component';
import { HomepageComponent } from './core/components/homepage/homepage.component';

@NgModule({
    declarations: [AppComponent, LearningPreparationFormComponent, HomepageComponent],
    imports: [BrowserModule, AppRoutingModule, SharedModule],
    providers: [provideHttpClient()],
    bootstrap: [AppComponent],
})
export class AppModule {}
