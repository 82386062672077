import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomepageComponent } from './core/components/homepage/homepage.component';

const routes: Routes = [
	{
		path: '',
		component: HomepageComponent,
	},
	{
		path: 'user-persona',
		loadChildren: () =>
			import('./core/modules/user-persona/userPersona.module').then(
				(m) => m.UserPersonaModule
			),
	},
	{
		path: 'conversations',
		loadChildren: () =>
			import(
				'./core/modules/conversation-ai/conversation-ai.module'
			).then((m) => m.ConversationAiModule),
	},
	// {
	// 	path: 'auth',
	// 	loadChildren: () =>
	// 		import(
	// 			'./core/modules/auth/auth.module'
	// 		).then((m) => m.AuthModule),
	// },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
