import { Component } from '@angular/core';

@Component({
  selector: 'app-learning-preparation-form',
  templateUrl: './learning-preparation-form.component.html',
  styleUrl: './learning-preparation-form.component.less'
})
export class LearningPreparationFormComponent {

}
