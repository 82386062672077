<div class="home-container">
	<section data-type="main-hero" class="section-container">
		<div class="section-content">
			<!-- <div class="section-image">
				<img src="./../../../../assets/images/homepage/bg-gradients/gradient-hero.jpg" />
			</div> -->
			<div class="content-primary">
				<h1 class="title">
					Leap forward your career with our innovative & personalized Conversational-AI
				</h1>
				<div class="desc">
					A recruiter takes 6-7 seconds to gauge your suitability for the job. A focused resume with all the right
				information on the first page grabs their attention.
				</div>
				<div class="button-container">
					<button class="btn btn-primary">Get Started. Try it for Free!</button>
				</div>
			</div>
			<div class="content-secondary">
				<div class="home-discount">
					<div class="discount-name">Grab the discount upto</div>
					<div class="discount-perc">10%</div>
					<div class="discount-clock">
						<div class="discount-counter">
							<span>10</span>
							<span>days</span>
						</div>
						<div class="discount-counter">
							<span>05</span>
							<span>hours</span>
						</div>
						<div class="discount-counter">
							<span>20</span>
							<span>mins</span>
						</div>
						<div class="discount-counter">
							<span>29</span>
							<span>secs</span>
						</div>
					</div>
					<!-- <div class="discount-othertext">Hurry! Time is running out.</div> -->
				</div>
			</div>
		</div>
	</section>

	<section data-type="pam-intro" class="section-container">
		<div class="section-content">
			<div class="content-primary">
				<div class="title">Hello! I am PAM. <br />Your personalized learning assistant</div>
				<div class="desc">
					Build a beautiful resume with the power of generative AI that intelligently portrays all your aspects to
					the recruiter at a glance, helping you stand out and make an impression.
				</div>
				<div class="button-container" data-type="bordered">
					<button class="btn btn-primary cyan">Get Free Access to AVA!</button>
				</div>
			</div>
			<div class="section-image">
				<img src="./../../../../assets/images/homepage/viewport/pam-grid.png" />
			</div>
		</div>
	</section>

	<section data-type="pam-learn" class="section-container">
		<div class="section-content">
			<div class="section-image">
				<!-- <img src="./../../../../assets/images/home/ava.png" /> -->
			</div>
			<div class="content-primary">
				<div class="title">Unlock the power of AI with fastest and cheapest learning</div>
				<div class="desc">
					Build a beautiful resume with the power of generative AI that intelligently portrays all your aspects to
					the recruiter at a glance, helping you stand out and make an impression.
				</div>
				<div class="button-container" data-type="bordered">
					<button class="btn btn-primary">Get Free Access to AVA!</button>
				</div>
				<div class="stats-container">
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section data-type="pam-assessment" class="section-container">
		<div class="section-content">
			<div class="content-primary">
				<div class="title">Experience insightfull assessment which prepare you for the final exams</div>
				<div class="desc">
					Build a beautiful resume with the power of generative AI that intelligently portrays all your aspects to
					the recruiter at a glance, helping you stand out and make an impression.
				</div>
				<div class="button-container" data-type="bordered">
					<button class="btn btn-primary cyan">Get Free Access to AVA!</button>
				</div>
				<div class="stats-container">
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
				</div>
			</div>
			<div class="section-image">
				<!-- <img src="./../../../../assets/images/home/ava.png" /> -->
			</div>
		</div>
	</section>

	<section data-type="pam-interview" class="section-container">
		<div class="section-content">
			<div class="section-image">
				<!-- <img src="./../../../../assets/images/home/ava.png" /> -->
			</div>
			<div class="content-primary">
				<div class="title">Ace your interview with continous mockup sessions</div>
				<div class="desc">
					Build a beautiful resume with the power of generative AI that intelligently portrays all your aspects to
					the recruiter at a glance, helping you stand out and make an impression.
				</div>
				<div class="button-container" data-type="bordered">
					<button class="btn btn-primary">Get Free Access to AVA!</button>
				</div>
				<div class="stats-container">
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section data-type="ava-intro" class="section-container">
		<div class="section-content">
			<div class="content-primary">
				<div class="title">Art of resume making simplified, with the power of AVA</div>
				<div class="desc">
					Build a beautiful resume with the power of generative AI that intelligently portrays all your aspects to
					the recruiter at a glance, helping you stand out and make an impression.
				</div>
				<div class="button-container" data-type="bordered">
					<button class="btn btn-primary cyan">Get Free Access to AVA!</button>
				</div>
			</div>
			<div class="section-image">
				<!-- <img src="./../../../../assets/images/home/ava.png" /> -->
			</div>
		</div>
	</section>

	<section data-type="ava-cv" class="section-container">
		<div class="section-content">
			<div class="section-image">
				<!-- <img src="./../../../../assets/images/home/ava.png" /> -->
			</div>
			<div class="content-primary">
				<div class="title">Craft beautiful resume & create an impression of recruiters' mind</div>
				<div class="desc">
					Build a beautiful resume with the power of generative AI that intelligently portrays all your aspects to
					the recruiter at a glance, helping you stand out and make an impression.
				</div>
				<div class="button-container" data-type="bordered">
					<button class="btn btn-primary">Get Free Access to AVA!</button>
				</div>
				<div class="stats-container">
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
					<div class="stats">
						<div class="title">40%</div>
						<div class="desc">Increase in Retention</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!-- <app-subscription-plan></app-subscription-plan> -->
	
	<section data-type="opportunities" class="section-container">
		<div class="section-content">
			<div class="content-primary" data-type="partner">
				<div class="title">Become a Channel Partner</div>
				<div class="desc">
					Build a beautiful resume with the power of generative AI that intelligently portrays.
				</div>
				<div class="button-container" data-type="bordered">
					<button class="btn btn-primary btn-bordered cyan small">Join Our Partners Team</button>
				</div>
			</div>
			<div class="content-primary" data-type="college">
				<div class="title">Invite us to your College</div>
				<div class="desc">
					Build a beautiful resume with the power of generative AI that intelligently portrays.
				</div>
				<div class="button-container" data-type="bordered">
					<button class="btn btn-primary btn-bordered cyan small">Send Invitation</button>
				</div>
			</div>
			<div class="content-primary" data-type="college">
				<div class="title">Campus Ambassador Program</div>
				<div class="desc">
					Build a beautiful resume with the power of generative AI that intelligently portrays.
				</div>
				<div class="button-container" data-type="bordered">
					<button class="btn btn-primary btn-bordered cyan small">Send Invitation</button>
				</div>
			</div>
		</div>
	</section>
	<section data-type="testimonials" class="section-container">
		<div class="section-content">
			<div class="content-primary">
				<!-- <div class="title">Customers are loving us!</div> -->
				<div class="testimonials-list-container">
					<div class="testimonial-container">
						<div class="quote-sign">
							<img src="./../../../../assets/images/homepage/quotes.png" />
						</div>
						<div class="testimonial-content">
							Build a beautiful resume with the power of generative AI that intelligently portrays all your aspects to the recruiter at a glance, helping you stand out and make an impression. Build a beautiful resume with the power of generative AI that intelligently portrays all your aspects to the recruiter at a glance, helping you stand out and make an impression. 
						</div>
						<div class="userphoto-container">
							<img src="./../../../../assets/images/icons/user-default-gray-64-64.png"
								style="padding: 10px;">
						</div>
						<div class="userdesc-container">
							<!-- <div class="type">Candidate</div> -->
							<div class="name">Devvrat Arya</div>
							<div class="role">Software Engg</div>
							<div class="testimonial-rating">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section data-type="blogs" class="section-container">
		<div class="section-content">
			<div class="content-primary">
				<div class="title">Blogs</div>
				<div class="blog-snapshot-list">
					<div class="blog-snapshot">
						<img src="./../../../../assets/images/home/ambassador.jpg" />
						<div class="title">Art of resume making simplified, with Gen-AI</div>
						<div class="desc">
							Build a beautiful resume with the power of generative AI that intelligently portrays all ...
						</div>
						<div class="button-container" data-type="bordered">
							<button class="btn btn-primary small">Read More ...</button>
						</div>
					</div>
					<div class="blog-snapshot">
						<img src="./../../../../assets/images/home/ambassador.jpg" />
						<div class="title">Art of resume making simplified, with Gen-AI</div>
						<div class="desc">
							Build a beautiful resume with the power of generative AI that intelligently portrays all ...
						</div>
						<div class="button-container" data-type="bordered">
							<button class="btn btn-primary small">Read More ...</button>
						</div>
					</div>
					<div class="blog-snapshot">
						<img src="./../../../../assets/images/home/ambassador.jpg" />
						<div class="title">Art of resume making simplified, with Gen-AI</div>
						<div class="desc">
							Build a beautiful resume with the power of generative AI that intelligently portrays all ...
						</div>
						<div class="button-container" data-type="bordered">
							<button class="btn btn-primary small">Read More ...</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
